<template>
	<div id="app">
		<a-modal :title="modal.title" :visible="modal.show" @ok="$store.dispatch('CONFIRM_MODAL')"
			:confirmLoading="modal.load" @cancel="$store.commit('CLOSE_MODAL')">
			<p>{{ modal.text }}</p>
		</a-modal>
		<BHLoading :show="$store.state.loading" />
		<MediaSelector />
		<localization></localization>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Localization from 'bh-mod/localization'
import BHLoading from 'bh-mod/components/common/Loading'
import MediaSelector from 'bh-mod/components/file/MediaSelector'



export default {
	name: 'app',
	components: { Localization, MediaSelector, BHLoading },
	computed: {
		...mapGetters(['user']),
		modal() {
			return this.$store.state.email.modal
		}
	},
	watch: {
		'$route'(to, from) {
			const query = Object.assign({}, to.query)
			this.$store.commit('SETUP_URL_SETTINGS', query)
			this.$store.commit('STORE_MIDDLEWARE', to)
		},
	},
	mounted() {
		window.addEventListener('resize', (e) => {
			this.$store.commit('VIEWPORT', { w: document.body.clientWidth, h: document.body.clientHeight })
		})
	}
}
</script>
<style lang="scss">
#app {
	min-height: 100vh;
}

.ant-drawer-body {
	padding: 0;
	margin: 0;
}

iframe {
	border: 0;
}

.ant-drawer-header-no-title [aria-label="Close"] {
	background: white;
}

.builder-edit-icon-circle {
	width: 20px;
	font-size: 12px;
	padding: 5px;
	border-radius: 50%;
	background: var(--light-purple);
	height: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	box-shadow: 0 2px 3px rgba(100, 100, 100, 0.4);
	color: var(--dark-gray);

	&:hover {
		background: var(--purple);
		color: white;
	}
}

.ant-spin-dot-item {
	background-color: var(--primary);
}
</style>

<style>
/* table left-right scroll arrows css */
.scroll-button {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
	background-color: rgb(220 220 220 / 50%);
	border: none;
	color: #000;
	font-size: large;
	padding: 10px;
	cursor: pointer;
	height: 100%;
	display: flex;
	align-items: center;
}

.scroll-left {
	left: 0;
}

.scroll-right {
	right: 0;
}

.hide {
	display: none;
}
</style>
